import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { switchMap, tap } from 'rxjs/operators';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { KeycloakService } from 'keycloak-angular';
import { PrimeNGConfig } from 'primeng/api';
import { ThgcLayoutModule } from '@thg-procure-team/procure-common-ui/platform/layout';
import {
  ThgcLocalizationModule,
  ThgcLocalizationService,
} from '@thg-procure-team/procure-common-ui/platform/localization';
import { ThgcConfirmModule } from '@thg-procure-team/procure-common-ui/platform/confirm';
import { ThgcConfigModule, ThgcConfigService } from '@thg-procure-team/procure-common-ui/platform/config';
import { ThgcToastModule } from '@thg-procure-team/procure-common-ui/platform/toast';
import { UserService } from '@thg-procure-team/procure-common-ui/business/user';
import { ThgcRouterResolveProgressbarModule } from '@thg-procure-team/procure-common-ui/platform/router-resolve-progressbar';
import { ThgcSpinnerModule } from '@thg-procure-team/procure-common-ui/platform/spinner';
import { MaintenanceModule } from '@thg-procure-team/procure-common-ui/platform/maintenance';

import { LayoutHomeComponent } from '@procure-user/core/layouts/layout-home/layout-home.component';
import { LayoutNotFoundComponent } from '@procure-user/core/layouts/layout-not-found/layout-not-found.component';
import { HttpModule } from '@procure-user/core/http/http.module';

@NgModule({
  declarations: [LayoutHomeComponent, LayoutNotFoundComponent],
  imports: [
    CommonModule,
    HttpModule,
    ThgcLayoutModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    ThgcConfirmModule,
    ThgcConfigModule,
    ThgcToastModule,
    ThgcLocalizationModule,
    ThgcRouterResolveProgressbarModule,
    ThgcSpinnerModule,
    MaintenanceModule,
  ],
  providers: [
    KeycloakService,
    HttpModule,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ThgcConfigService) => () =>
        configService
          .load()
          .pipe(
            tap((config) => {
              const meta = document.createElement('meta');
              meta.httpEquiv = 'Content-Security-Policy';
              meta.content = `default-src 'self'; style-src 'self' 'unsafe-inline'; img-src 'self' data:; connect-src ${config.cspConnectSrc}`;
              document.head.prepend(meta);
            }),
          )
          .toPromise(),
      multi: true,
      deps: [ThgcConfigService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (
        userService: UserService,
        thgcConfigService: ThgcConfigService,
        thgcLocalizationService: ThgcLocalizationService,
      ) => () =>
        thgcConfigService
          .load()
          .pipe(
            tap((config) => (thgcLocalizationService.availableLocales = config.availableLanguages)),
            switchMap((config) => userService.login(config.keyCloakConfig)),
          )
          .toPromise(),
      multi: true,
      deps: [UserService, ThgcConfigService, ThgcLocalizationService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (primengConfig: PrimeNGConfig) => () =>
        new Promise<void>((resolve) => {
          primengConfig.ripple = true;
          resolve();
        }),
      multi: true,
      deps: [PrimeNGConfig],
    },
    {
      provide: BsDatepickerConfig,
      useFactory: (thgcLocalizationService: ThgcLocalizationService) => ({
        ...new BsDatepickerConfig(),
        showWeekNumbers: false,
        dateInputFormat: thgcLocalizationService.translate('shared.dateFormat').toUpperCase(),
      }),
      deps: [ThgcLocalizationService],
    },
  ],
  exports: [
    BrowserAnimationsModule,
    HttpModule,
    LayoutHomeComponent,
    ThgcToastModule,
    ThgcConfigModule,
    ThgcConfigModule,
    MaintenanceModule,
    ModalModule,
    ThgcRouterResolveProgressbarModule,
  ],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentCoreModule: CoreModule,
    private readonly modalService: BsModalService,
  ) {
    if (parentCoreModule) {
      throw new Error(
        `${parentCoreModule.constructor.name} has already been loaded. Import this module in the AppModule only.`,
      );
    }
    this.modalService.config.class = 'modal-lg';
    this.modalService.config.ignoreBackdropClick = true;
    this.modalService.config.keyboard = false;
  }
}
