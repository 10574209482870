<div class="wrapper">
  <thgc-left-sidebar
    [isOpen]="isLeftSidebarOpen"
    [navigationMenu]="navigationMenu"
    (closeSidebar)="onLeftSidebarToggle(false)"
  >
  </thgc-left-sidebar>

  <div class="content-page">
    <thgc-announcement-banner
      [announcements]="announcements | async"
      (dismissAnnouncement)="toggleAnnouncementRead($event)"
    ></thgc-announcement-banner>

    <thgc-topbar
      [user]="user$ | async"
      [userMenu]="userMenu"
      [unreadAnnouncementCount]="unreadAnnouncementCount | async"
      (logout)="onLogout()"
      (localeChange)="onLocaleChange($event)"
      (rightSidebarToggle)="onRightSidebarToggle(!isRightSidebarOpen, $event)"
      (leftSidebarToggle)="onLeftSidebarToggle(!isLeftSidebarOpen)"
    >
    </thgc-topbar>

    <div class="page">
      <div class="container-fluid position-relative">
        <router-outlet *ngIf="canDisplayApplication"></router-outlet>

        <thgc-spinner *ngIf="!canDisplayApplication" [searchTitle]="'appLoading' | translate"></thgc-spinner>
      </div>
    </div>

    <thgc-right-sidebar
      [isOpen]="isRightSidebarOpen"
      [rightSidebarContentType]="rightSidebarContentType"
      [themeMenu]="themeMenu"
      [announcements]="announcements | async"
      (closeSidebar)="onRightSidebarToggle(false)"
      (themeChange)="onThemeChange($event)"
      (toggleAnnouncementRead)="toggleAnnouncementRead($event)"
    >
    </thgc-right-sidebar>

    <thgc-footer version="{{ 'version' | config }}"></thgc-footer>
  </div>
</div>
