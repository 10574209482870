import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, skip, startWith } from 'rxjs/operators';
import { AnnouncementInboxService } from '@thg-procure-team/procure-common-ui/business/announcement-inbox';
import { UserEntity, UserService } from '@thg-procure-team/procure-common-ui/business/user';
import { AutoUnsubscribable } from '@thg-procure-team/procure-common-ui/platform/domain';
import {
  AnnouncementEntity,
  NavigationMenuItem,
  RightSidebarContentType,
  ThemeMenuItem,
  UserMenuItem,
} from '@thg-procure-team/procure-common-ui/platform/layout';
import { ThgcLocalizationService } from '@thg-procure-team/procure-common-ui/platform/localization';
import { ColorType } from '@thg-procure-team/procure-common-ui/platform/theme';
import { ThgcConfigService } from '@thg-procure-team/procure-common-ui/platform/config';
import {
  FeatureFlag,
  FeatureFlagsApiService,
  isFeatureEnabled,
} from '@thg-procure-team/procure-common-ui/business/feature-flags';

import { NavigationStateService } from '@procure-user/core/store/navigation-state.service';
import { PermissionLevel } from '@procure-user/core/user/domain';

@Component({
  selector: 'thg-layout-home',
  templateUrl: './layout-home.component.html',
  styleUrls: ['./layout-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutHomeComponent extends AutoUnsubscribable implements OnInit {
  public isLeftSidebarOpen: boolean;
  public isRightSidebarOpen: boolean;
  public rightSidebarContentType: RightSidebarContentType;
  public canDisplayApplication: boolean;
  public navigationMenu: NavigationMenuItem[];
  public themeMenu: ThemeMenuItem[];
  public userMenu: UserMenuItem[];
  public user$: Observable<UserEntity>;
  public announcements: Observable<AnnouncementEntity[]>;
  public unreadAnnouncementCount: Observable<number>;
  private fundingRequestListAvailable: boolean;

  constructor(
    private readonly thgcLocalizationService: ThgcLocalizationService,
    private readonly navigationStateService: NavigationStateService,
    private readonly userService: UserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly announcementInboxService: AnnouncementInboxService,
    private readonly featureFlagsApiService: FeatureFlagsApiService,
    private readonly thgcConfigService: ThgcConfigService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subSink.sink = this.featureFlagsApiService.getFeatureFlags().subscribe((featureFlags) => {
      this.fundingRequestListAvailable =
        isFeatureEnabled(FeatureFlag.FUNDING_REQUEST_LIST, featureFlags) &&
        this.userService.hasPermissions({ 'Funding Request - View': PermissionLevel.FULL_CREATED_BY_ME });

      this.user$ = this.userService.user$;
      this.announcements = this.announcementInboxService.announcements$;
      this.unreadAnnouncementCount = this.announcementInboxService.unreadAnnouncementCount;
      this.setupMenuItems();
    });
  }

  public onLeftSidebarToggle(isLeftSidebarOpen: boolean): void {
    this.isLeftSidebarOpen = isLeftSidebarOpen;
  }

  public onRightSidebarToggle(isRightSidebarOpen: boolean, type?: RightSidebarContentType): void {
    this.isRightSidebarOpen = isRightSidebarOpen;
    this.rightSidebarContentType = type;
  }

  public onLogout(): void {
    this.userService.logout();
  }

  public onLocaleChange(locale: string): void {
    this.userService.setLanguage(locale);
  }

  public onThemeChange(theme: string): void {
    this.userService.setTheme(theme);
  }

  private setupMenuItems(): void {
    this.subSink.sink = this.userService.user$.subscribe(() => {
      this.loadRequisitionWorkflows();
    });
    this.subSink.sink = this.thgcLocalizationService.localeChanged$.subscribe(() => {
      this.themeMenu = [
        { label: this.thgcLocalizationService.translate('appSettings.darkThemeLabel'), key: 'dark' },
        { label: this.thgcLocalizationService.translate('appSettings.lightThemeLabel'), key: 'light' },
      ];
      this.userMenu = [
        {
          label: this.thgcLocalizationService.translate('topbar.delegateAuthorityLabel'),
          icon: 'mdi mdi-account-group',
          onClick: () => this.router.navigate(['/delegations']),
        },
      ];
      this.navigationMenu = [
        {
          label: this.thgcLocalizationService.translate('navigation.dashBoardTitle'),
          isTitle: true,
        },
        {
          label: this.thgcLocalizationService.translate('navigation.dashBoard'),
          icon: 'dripicons-bell',
          link: '/dashboard',
        },
        {
          label: this.thgcLocalizationService.translate('navigation.dashBoardReports'),
          icon: 'dripicons-meter',
          link: '/reports',
          exact: true,
        },
        {
          label: this.thgcLocalizationService.translate('navigation.procureTitle'),
          isTitle: true,
        },
        {
          label: this.thgcLocalizationService.translate('navigation.setupLabel'),
          icon: 'dripicons-gear',
          children: [
            {
              label: this.thgcLocalizationService.translate('navigation.businessUnitsLabel'),
              link: '/business-units',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.departments'),
              link: '/departments',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.reportDefinitionGroups'),
              link: '/reports/groups',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.reportsManagementLabel'),
              link: '/reports/management',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.rolesLabel'),
              link: '/roles',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.requisitionWorkflowTitleLabel'),
              link: '/requisition-workflows',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.requisitionTypeTitleLabel'),
              link: '/requisition-types',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.requisitionPurposeTitleLabel'),
              link: '/requisition-purposes',
            },
          ],
        },
        {
          label: this.thgcLocalizationService.translate('navigation.configLabel'),
          icon: 'dripicons-duplicate',
          children: [
            {
              label: this.thgcLocalizationService.translate('navigation.approvalLimitsLabel'),
              link: '/approval-limits',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.delegationManagementLabel'),
              link: '/delegations-management',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.usersLabel'),
              link: '/users',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.invoiceAutoAssignMapping'),
              link: '/invoice-auto-assign-mapping',
            },
          ],
        },
        {
          label: this.thgcLocalizationService.translate('navigation.dataLabel'),
          icon: 'uil uil-database',
          children: [
            {
              label: this.thgcLocalizationService.translate('navigation.productsLabel'),
              link: '/products',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.productGroupsLabel'),
              link: '/product-groups',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.supplierProductsPricesLabel'),
              link: '/supplier-products',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.indirectSupplierProductsLabel'),
              link: '/indirect-supplier-products',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.supplierEmailsLabel'),
              link: '/supplier-emails',
            },
          ],
        },
        {
          label: this.thgcLocalizationService.translate('navigation.fundingRequestsTitle'),
          icon: 'uil uil-money-bill-stack',
          link: '/funding-requests',
          isHidden: !this.fundingRequestListAvailable,
        },
        {
          label: this.thgcLocalizationService.translate('navigation.requisitionsTitle'),
          icon: 'uil uil-suitcase',
          children: [],
        },
        {
          label: this.thgcLocalizationService.translate('navigation.purchaseOrdersLabel'),
          icon: 'uil uil-invoice',
          children: [],
        },
        {
          label: this.thgcLocalizationService.translate('navigation.deliveriesLabel'),
          icon: 'uil uil-truck',
          link: '/deliveries',
        },
        {
          label: this.thgcLocalizationService.translate('navigation.invoicesLabel'),
          icon: 'dripicons-document',
          children: [],
        },
      ];

      this.watchRouterNavigation();
      this.loadRequisitionWorkflows();
      this.changeDetectorRef.markForCheck();
    });
  }

  private watchRouterNavigation(): void {
    this.subSink.sink = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        startWith([]),
      )
      .subscribe(() => this.updateDashboardBadge());
  }

  private updateDashboardBadge(): void {
    const dashboardIndex = 1;
    this.navigationStateService.loadDashboardNotifications();
    this.subSink.sink = this.navigationStateService.pendingActionsCount$
      .pipe()
      .subscribe((pendingActionsCount) => {
        const dashboardNavigationItem = this.navigationMenu[dashboardIndex];
        if (pendingActionsCount && pendingActionsCount > 0) {
          const badgeText = pendingActionsCount > 99 ? '99+' : pendingActionsCount.toString();
          dashboardNavigationItem.badge = { colorType: ColorType.DANGER, text: badgeText };
        } else {
          dashboardNavigationItem.badge = null;
        }

        this.navigationMenu = [...this.navigationMenu];
      });
  }

  private findMenuItemIndexByLabel(menuItemLabel: string): number {
    return this.navigationMenu.findIndex((menuItem) => menuItem.label && menuItem.label === menuItemLabel);
  }

  private loadRequisitionWorkflows(): void {
    this.navigationStateService.loadRequisitionWorkflows();
    this.subSink.sink = this.navigationStateService.requisitionWorkflows$
      .pipe(skip(1))
      .subscribe((workflowList) => {
        const requisitionMenuIndex = this.findMenuItemIndexByLabel(
          this.thgcLocalizationService.translate('navigation.requisitionsTitle'),
        );
        const purchaseOrderMenuIndex = this.findMenuItemIndexByLabel(
          this.thgcLocalizationService.translate('navigation.purchaseOrdersLabel'),
        );
        const invoicesMenuIndex = this.findMenuItemIndexByLabel(
          this.thgcLocalizationService.translate('navigation.invoicesLabel'),
        );
        this.navigationMenu[requisitionMenuIndex].children = [];
        this.navigationMenu[purchaseOrderMenuIndex].children = [];
        this.navigationMenu[invoicesMenuIndex].children = [];
        workflowList.forEach((workflow) => {
          if (
            this.userService.hasPermissions({
              [workflow.name + ' Procurement']: PermissionLevel.FULL_ALL_BUSINESS_UNITS,
            })
          ) {
            this.navigationMenu[requisitionMenuIndex].children.push({
              label: this.thgcLocalizationService.translate('navigation.requisitionWorkflowLabel', [
                workflow.name,
              ]),
              link: `/requisition-workflows/${workflow.id}/requisitions`,
            });
            this.navigationMenu[purchaseOrderMenuIndex].children.push({
              label: this.thgcLocalizationService.translate('navigation.purchaseOrderWorkflowLabel', [
                workflow.name,
              ]),
              link: `/requisition-workflows/${workflow.id}/purchase-orders`,
            });
            this.navigationMenu[invoicesMenuIndex].children.push({
              label: this.thgcLocalizationService.translate('navigation.invoicesWorkflowLabel', [workflow.name]),
              link: `/requisition-workflows/${workflow.id}/invoices`,
            });
          }
        });
        if (!this.navigationMenu[invoicesMenuIndex].children.length) {
          this.navigationMenu.splice(invoicesMenuIndex, 1);
        }
        if (!this.navigationMenu[requisitionMenuIndex].children.length) {
          this.navigationMenu.splice(requisitionMenuIndex, 2);
        }
        this.navigationMenu = [...this.navigationMenu];
        this.canDisplayApplication = true;
        this.changeDetectorRef.detectChanges();
      });
  }

  public toggleAnnouncementRead(announcement: AnnouncementEntity): void {
    this.announcementInboxService.toggleAnnouncementRead(announcement);
  }
}
