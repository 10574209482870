import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { UserService } from '@thg-procure-team/procure-common-ui/business/user';
import { hasActiveDelegation } from '@procure-user/feature/delegations/delegations.utils';

@Injectable({
  providedIn: 'root',
})
export class DelegationsGuard implements CanLoad {
  constructor(private readonly userService: UserService, private readonly router: Router) {}

  public canLoad(route: Route): boolean {
    if (hasActiveDelegation(this.userService.getUserSnapshot())) {
      this.router.navigate(['/delegations']);
      return false;
    }

    return true;
  }
}
