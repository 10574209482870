import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@thg-procure-team/procure-common-ui/business/user';
import { ThgcLocalizationService } from '@thg-procure-team/procure-common-ui/platform/localization';
import { ThgcToastService } from '@thg-procure-team/procure-common-ui/platform/toast';
import { SubSink } from '@thg-procure-team/procure-common-ui/platform/utils';

import { hasActiveDelegation } from '@procure-user/feature/delegations/delegations.utils';
import { PermissionLevel } from '@procure-user/core/user/domain';

@Component({
  selector: 'thg-layouts-not-found',
  templateUrl: './layout-not-found.component.html',
  styleUrls: ['./layout-not-found.component.scss'],
})
export class LayoutNotFoundComponent implements OnInit, OnDestroy {
  public hasActiveDelegation: boolean;
  public hasDashboardPermission: boolean;
  public notFoundTitle: string;
  public notFoundMessage: string;
  public homePageLabel: string;
  public readonly subSink: SubSink = new SubSink();

  constructor(
    private readonly userService: UserService,
    private readonly thgcLocalizationService: ThgcLocalizationService,
    private readonly thgcToastService: ThgcToastService,
    private readonly router: Router,
  ) {}

  public ngOnInit() {
    this.hasActiveDelegation = hasActiveDelegation(this.userService.getUserSnapshot());
    this.hasDashboardPermission = this.userService.hasPermissions({
      'User Dashboard': PermissionLevel.FULL_ALL_BUSINESS_UNITS,
    });

    this.subSink.sink = this.thgcLocalizationService.localeChanged$.subscribe(() => {
      this.notFoundTitle = this.thgcLocalizationService.translate('shared.notFoundTitle');
      this.notFoundMessage = this.thgcLocalizationService.translate('shared.notFoundMessage');
      this.homePageLabel = this.hasActiveDelegation
        ? this.thgcLocalizationService.translate('shared.delegationLinkLabel')
        : this.thgcLocalizationService.translate('shared.homeLinkLabel');
    });
  }

  public ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  public onGoToHomeClick(): void {
    if (!this.hasActiveDelegation && !this.hasDashboardPermission) {
      this.thgcToastService.showError(
        this.thgcLocalizationService.translate('shared.errorHeader'),
        this.thgcLocalizationService.translate('dashboard.accessDeniedErrorMessage'),
      );
    } else {
      this.router.navigate([this.hasActiveDelegation ? '/delegations' : '/dashboard']);
    }
  }
}
