import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AutoUnsubscribable, Status } from '@thg-procure-team/procure-common-ui/platform/domain';
import { PendingActionsApiService } from '@thg-procure-team/procure-common-ui/business/pending-actions';
import { RequisitionWorkflowDto } from '@thg-procure-team/procure-common-ui/business/requisition-workflows';

import { RequisitionWorkflowsApiService } from '@procure-user/feature/requisition-workflows/services/requisition-workflows-api.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationStateService extends AutoUnsubscribable {
  private _requisitionWorkflows$: BehaviorSubject<RequisitionWorkflowDto[]> = new BehaviorSubject<
    RequisitionWorkflowDto[]
  >([]);

  public readonly requisitionWorkflows$: Observable<
    RequisitionWorkflowDto[]
  > = this._requisitionWorkflows$.asObservable();

  private _pendingActionsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public readonly pendingActionsCount$: Observable<number> = this._pendingActionsCount$.asObservable();

  constructor(
    private readonly requisitionWorkflowsApiService: RequisitionWorkflowsApiService,
    private readonly pendingActionsApiService: PendingActionsApiService,
  ) {
    super();
  }

  public loadRequisitionWorkflows(): void {
    this.subSink.sink = this.requisitionWorkflowsApiService.getList({ status: Status.ACTIVE }).subscribe(
      (requisitionWorkflowList) => this._requisitionWorkflows$.next(requisitionWorkflowList),
      () => this._requisitionWorkflows$.next([]),
    );
  }

  public loadDashboardNotifications(): void {
    this.subSink.sink = this.pendingActionsApiService.getPendingActionCount().subscribe(
      (pendingActionCount) => this._pendingActionsCount$.next(pendingActionCount),
      () => this._pendingActionsCount$.next(0),
    );
  }

  public getWorkflowsSnapshot(): RequisitionWorkflowDto[] {
    return this._requisitionWorkflows$.getValue();
  }

  public getSelectedWorkflow(selectedWorkflowId: string): RequisitionWorkflowDto {
    return this.getWorkflowsSnapshot().find((workflow) => workflow.id === selectedWorkflowId);
  }
}
