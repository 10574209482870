import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { DelegationEntity, UserEntity } from '@thg-procure-team/procure-common-ui/business/user';

dayjs.extend(isBetween);

export const isCreatedDelegationActive = (delegation: DelegationEntity): boolean => {
  const todayDate = dayjs();

  return (
    todayDate.isBetween(delegation.dateFrom, delegation.dateUntil) || todayDate.isSame(delegation.dateFrom, 'date')
  );
};

export const hasActiveDelegation = (user: UserEntity): boolean => {
  return !!user.delegationTo;
};
