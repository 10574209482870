import { Injectable } from '@angular/core';
import { ApiService, HttpMethod, ListResponse } from '@thg-procure-team/procure-common-ui/platform/api';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Status } from '@thg-procure-team/procure-common-ui/platform/domain';
import {
  RequisitionWorkflowDto,
  RequisitionWorkflowListQuery,
} from '@thg-procure-team/procure-common-ui/business/requisition-workflows';

@Injectable({
  providedIn: 'root',
})
export class RequisitionWorkflowsApiService extends ApiService {
  protected readonly baseUrl: string = '/v1/requisition-workflows';

  constructor(protected readonly httpClient: HttpClient) {
    super(httpClient);
  }

  public getList(query: { status: Status }): Observable<RequisitionWorkflowDto[]>;
  public getList(query: RequisitionWorkflowListQuery): Observable<ListResponse<RequisitionWorkflowDto>>;
  public getList(query: any): Observable<any> {
    return this.request({
      method: HttpMethod.GET,
      url: this.baseUrl,
      params: query,
    });
  }

  public getById(id: string): Observable<RequisitionWorkflowDto> {
    return this.request({ method: HttpMethod.GET, url: `${this.baseUrl}/${id}` });
  }

  public create(payload: Partial<RequisitionWorkflowDto>): Observable<RequisitionWorkflowDto> {
    return this.request({ method: HttpMethod.POST, url: this.baseUrl, body: payload });
  }

  public update(id: string, payload: Partial<RequisitionWorkflowDto>): Observable<RequisitionWorkflowDto> {
    return this.request({ method: HttpMethod.PUT, url: `${this.baseUrl}/${id}`, body: payload });
  }
}
